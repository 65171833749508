import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import React from "react";
import { createRoot } from "react-dom/client";
// import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";
import { RecoilRoot } from "recoil";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import App from "./App";
import "./index.css";
import HooksProvider from "./state/hooks";

// const activeChainId = ChainId.Polygon;

import { WagmiConfig, configureChains, createConfig } from "wagmi";

import { goerli, mainnet } from "wagmi/chains";

import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, goerli],
  [
    alchemyProvider({ apiKey: "OweK1VI_SsSgJoVwa-vtvbqslPGtzve4" }),
    publicProvider(),
  ],
);

// Set up client
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: process.env.WALLETCONNECT_PROJECT_ID,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "hdao",
      },
    }),
    // new InjectedConnector({
    //   chains,
    //   options: {
    //     name: "Injected",
    //     shimDisconnect: true,
    //   },
    // }),
  ],
  provider,
  webSocketProvider,
});

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      {/* <ThirdwebProvider desiredChainId={activeChainId}> */}
      <WagmiConfig config={config}>
        <RecoilRoot>
          <HooksProvider>
            <App />
          </HooksProvider>
        </RecoilRoot>
      </WagmiConfig>
      {/* </ThirdwebProvider> */}
    </StyledEngineProvider>
  </React.StrictMode>,
);
